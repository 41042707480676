import React from 'react';
import '../componets/styles/navbar.css';
import background from '../componets/Imags/background.png';

export default function Coursle() {
  return (
    <>
      <div className='Coursle containers'>
        <img src={background} alt="Background pattern image" />
      </div>
    </>
  );
}
