import React from "react";

export default function Footer() {
  return (
    <>
      <div className="footer">
       
        Copyright © 2023 Developed By<a href="https://www.linkedin.com/in/loai-abed" target="_blank" rel="noreferrer"> loai abed </a>All receive Saved
      </div>
    </>
  );
}
